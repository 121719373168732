.faleconosco_container {
  background-color: #F4F4F4;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.faleconosco_description {
  
  display: block;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
  


}

.faleconosco_description_title > h1 {
  font-size: 14px;
  text-align: center;
  color: #5e6f74;
}


.button_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.faleconosco_button {
  padding: 10px;
  background-color: #9e7677;
width: 180px;
  border-radius: 25px;
  margin-top: 10px;
  margin-left: 10px;
}

.faleconosco_button > h1 {
  font-size: 14px;
  background-color: #9e7677;
  color: #ffffff;
  text-align: center;
}
.faleconosco_button > h1 >a {
  text-decoration: none;
  color: #fff;
  
  
  
}
