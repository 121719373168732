.institucionalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  /* background-image: url("https://jejcaldeiraria.s3.us-east-2.amazonaws.com/bc-institucional.jpg"); */
  background-color: #9E7677;
}

.institucional {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 2rem;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;
}

.institucionalTitulo {
  font-weight: bold;
  font-size: 38px;
  color: #ffffff;
  margin-bottom: 10px;
}

.institucionalTexto {
  font-size: 16px;
  color: #FFFFFF;
}



@media screen and (max-width: 767px) {
  .institucional {
    display: grid;
    grid-template-columns: auto  ;}
  
  
}