.copy {
  display: flex;
  max-width: 1200;
  font-size: 10px;
  margin-top: 15px;
  
}

.copySubFooter {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #9E7677;
  color: #fff;
}




