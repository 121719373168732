.clientesItems {
  
  max-width: 230px;
  width: 100%;

  border-radius: 5px;
  padding: 10px;
}













.images{
  width: 100%;
}