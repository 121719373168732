.SobreNoscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.SobreNoscontainer2 {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
}

.titleSobreNos_st {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9e7677;
  margin-bottom: 20px;
}
.titleSobreNos_st > p {
  width: 30%;
}

.SobreNoscontainer_desc {
  margin-bottom: 40px;
}

.SobreNoscontainer_desc_text > p {
  color: #5e6f74;
}
.SobreNoscontainer_content {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-areas: "foto descricao";
  grid-gap: 1rem;
}
.SobreNoscontainer_accordeon {
}
.SobreNoscontainer_image > img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .SobreNoscontainer_content {
    display: grid;
    grid-template-columns: auto;
    grid-template-areas:
      "descricao"
      "foto";
  }
}
