.footerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.footerContainerContent {
  display: grid;
  grid-template-columns: auto auto auto ;
  grid-gap: 3rem;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  width: 1200px;
  padding-bottom: 40px;
}

.footerDados {
  color: #9E7677;
  font-size: 14px;
  font-weight: 400;
}

.footerLogo {
  margin-bottom: 20px;
}

.footerDesc>p {
  margin-bottom: 10px;
}

.endereco{
  margin-bottom: 40px;
}
.footerServicosTitulo {
  color: #9E7677;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.footerServicosItems {
  color: #9E7677;
  font-size: 14px;
  font-weight: 400;
}

.footerServicosItems>ul>li {
  list-style: none;
  line-height: 2.0;
  padding-left: 5px;
}

.footerPortfolioTitulo {
  color: #9E7677;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.footerSocialTitulo {
  color: #9E7677;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.footerSocialItems {
  display: flex;
  
}

.footerSocialItemsIco{
margin-left: 15px;
}

.linksocial{
 color: #9E7677;
}

@media screen and (max-width: 768px) {
 .footerContainerContent {
   display: grid;
   grid-template-columns: auto }  ;


   .footerSocialTitulo {
    font-size: 18px;
 
  }
 
  
  
  
  
  





  

  .footerServicosTitulo {
    
    
    font-size: 38px;
  
  }
  
  .footerServicosItems {
    
    font-size: 18px;
    
  }
  
  .footerSocialTitulo {
    
    
    font-size: 38px;
    line-height: 1.2;
    margin-bottom: 25px;
  }
  .footerDados {
    color: #9E7677;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    
  }
  

  .footerPortfolioTitulo {
 
    line-height: 1.2;
    font-size: 38px;
    
  }



}