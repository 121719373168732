* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;


}

.home, .services, .products, .sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.LabelBottomNavigationcContainer {
  background-color: #007ACB;
}

.LabelBottomNavigation {
  visibility: hidden;
  background-color: #0099FE
}

.copy {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-bottom: 35px;
}

@media screen and (max-width: 959px) {
  .LabelBottomNavigation {
    visibility: visible;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}