.BannerContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-image: url("https://jejcaldeiraria.s3.us-east-2.amazonaws.com/bc-institucional.jpg");


}

.conteudo{
  display: flex;

max-width: 1200px;
padding-top: 40px;
padding-bottom: 40px;
padding-left: 20px;
padding-right: 20px;
}


.texto >h1{
  color: #FE951B;
}

.texto >p{
  color: #ffffff;
  margin-top: 10px;
}


.buttonDonwload{
  background-color: #FE951B;
  font-size: 18px;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;

}