.containerClientesItemsCardPrincipal {
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerClientesItemsCard {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-gap: 2rem;
  max-width: 1200px;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}


.clientesTitle {
  color: #FE951B;
  font-weight: bold;
  font-size: 48px;
 margin-top: 40px;
 padding-left: 20px;
}



@media screen and (max-width: 979px) {
  .containerClientesItemsCard {
    display: grid;
    grid-template-columns: auto auto auto ;}
  
  
}

@media screen and (max-width: 767px) {
  .containerClientesItemsCard {
    display: grid;
    grid-template-columns: auto auto  ;}
  
  
}


@media screen and (max-width: 479px) {
  .containerClientesItemsCard {
    display: grid;
    grid-template-columns: auto auto  ;}
  
  
}

@media screen and (max-width: 411px) {
  .containerClientesItemsCard {
    display: grid;
    grid-template-columns: auto  auto  ;}
  
  
}




@media screen and (max-width: 360px) {
  .containerServicesItemsCard {
    display: grid;
    grid-template-columns: auto auto  ;}
  
  
}