.msContainer {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msContainerDescription {
  width: 1200px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.msTitle {
  color: #fe951b;
  font-weight: bold;
  font-size: 23px;
  margin-bottom: 20px;
  line-height: 1.2;
}

.msText {
  font-size: 24px;
  color: #5f5f5f;
  line-height: 1.2;
  margin-bottom: 20px;
  padding-left: 40px;
  padding-right: 20px;
}





@media screen and (max-width: 600px) {
  .msText {
    font-size: 18px;
    }
}
