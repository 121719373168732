.ServicesItems_st {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto auto;
}

.titleServicesItems_st {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9e7677;
  margin-bottom: 20px;
  text-align: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container2 {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
}

.social {
  display: flex;
  align-items: center;
}

.social > p {
  margin-top: 20px;

  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .ServicesItems_st {
    grid-template-columns: auto;
  }
}
