.contatoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.contatoContainerContent {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;
}

.conatoDesc {
  margin-bottom: 40px;
}
.conatoDescTitulo {
  font-weight: bold;
  font-size: 2rem;
  color: #9E7677;
  margin-bottom: 20px;
  line-height: 1.2;
}

.conatoDescTexto {
  font-size: 24px;
  color: #5f5f5f;
  line-height: 1.2;
  margin-bottom: 20px;
}

.contatoNome {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 20px;
}

.contatoContent {
  display: grid;
  grid-template-columns: auto auto;
}

.mail {
  margin-top: 20px;
}


@media screen and (max-width: 479px) {
  .contatoContent {
    display: grid;
    grid-template-columns: auto ;
  }

  .conatoDescTitulo {
    
    font-size: 38px;
    
    
    
  }

}

.fone{
  text-decoration: none;
  color: #000000;
}