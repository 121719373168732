.fieldContato {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #5F5F5F;;
}

.Fiels {
  margin-bottom: 20px;
}

.fieldContatoMensagem {
  width: 100%;
  height: 15%x;
  border-radius: 5px;
  padding: 10px;
  border: solid 1px #5F5F5F;;
}

.buttonEnvuar{
  width: 100%;
height: 35px;
border-radius: 5px;
color: #ffff;
background-color: rgb(0, 170, 57);
border: none;
}


.buttoncomoChegar{
  width: 150px;
height: 35px;
border-radius: 5px;
color: #ffff;
background-color: #9E7677;
border: none;
margin-top: 10px;
}

.h1FormAtendimento_st{
  color: #9E7677;
  margin-top: 25px;
}