.ServicesItemCard_st {
  border: solid 1px #e4e4e4;
  width: 100%;
  height: 100px;
  border-radius: 3px;
  background-color: #fffdfd;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}

.ServicesItemSpaceCard_st {
  margin-bottom: 1rem;
  cursor: pointer;
}

.ServicesItemCard_st:hover {
  border: solid 1px #FFF;
  background-color: #00ADF4;
  color: #fff;
  transition: all 0.3s ease-out;
  z-index: 999;
}

.tituloServico_st {
 
  padding-left: 10px;
  padding-right: 20px;
}

.tituloServicoCard_st {
 
  font-weight: 700;
  font-size: 1.1rem;
  color: #5e6f74;
  margin-bottom: 10px;
}

.subtituloServicoCard_st {
 
  font-weight: 400;
  font-size: 0.9rem;
  color: #A5A5A5;
}

.ServicesItemCard_st h1 {
 
  font-size: 1.2rem;
  font-weight: 400;
}

.icoServico_st {
  width: 50px;
  height: 50px;
  margin-left: 15px;
}

.icoServico_st img {
  width: 50px;
  height: 50px;
}