image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: relative;
}

.Banner_st {
  margin-bottom: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.bcBanner_st {
  height: 75vh;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);

  background-image: url("https://capconsult.s3.us-east-2.amazonaws.com/B1.jpeg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top left, center center;
  background-repeat: repeat, no-repeat;
}

.intro {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  width: 79%;
  padding-top: 10px;
}

.intro > h1 {
  font-family: "Raleway", sans-serif;
  color: rgb(255, 255, 255);
  font-size: 80px;
  margin-top: -200px;
  text-align: center;
  width: 80%;
  line-height: 1.2;
  /* border-left: solid 10px #9E7677; */
  padding-left: 0px;
}

.intro > p {
  margin-top: 8px;
  font-family: "Jost", sans-serif;
}

.horacontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #9e7677;
  width: 90%;
}

.horacontainer2 {
  display: grid;
  grid-template-columns: auto auto auto;
}

.horacontainer_content {
  background-color: #9e7677;
  color: #fff;
  display: flex;
  align-items: center;

  padding: 10px;
  height: 80px;
}

.horacontainer_content2 {
  background-color: #a78686;
  color: #fff;
  display: flex;
  align-items: center;
 
  padding: 10px;
}

.content > a {
  color: #fff;
  text-decoration: none;
}

.horatitle {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
}

.content {
  padding-left: 10px;
}

@media screen and (max-width: 1980px) {
  .intro > h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 1366px) {
  .intro > h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 979px) {
  .intro > h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 767px) {
  .intro > h1 {
    font-size: 30px;
  }
  .intro {
    width: 90%;
  }

  .horacontainer2 {
    display: grid;
    grid-template-columns: auto;
  }
}
