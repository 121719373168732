.consultcontainer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.consultcontainer2{
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
}

.titleconsult_st {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9e7677;
  margin-bottom: 20px;
  text-align: center;
}
.titleconsult_st>p {
  width: 30%;
  
  
  
  
}





.consultcontainer_desc{
 text-align: center;

  margin-bottom: 40px;
  
}

.consultcontainer_desc>p{
  color: #5e6f74;
 
}



.consultcontainer_content{ 
  display: grid;
  grid-template-columns: 70% 30%;
  grid-gap: 1rem;
}
.consultcontainer_accordeon{}
.consultcontainer_image>img{
  width: 100%;
}



@media screen and (max-width: 767px) {
  .consultcontainer_content{ 
    display: grid;
    grid-template-columns: auto;
  
  }
  
}