.Gallery_st {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.GalleryContainer{
  max-width: 1200px;
  width: 90%;
}
.tituloGallery_st {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #4D4D4D;
}

.GaleriaServicos {
  height: 100vh;
}

.galeriaCompleta {
  justify-content: center;
  align-items: center;
  display: flex;
}

.insta{
  height: 35px;
  background-color: rgb(223, 0, 97);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
}