.tituloservicoconsultoria{
  color: #5e6f74;
}

.tituloservicoconsultoria>span{
  color:#9e7677;
}

.accordion_text{
  color: #5e6f74;
}